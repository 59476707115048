<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 8.615 5.743">
    <path :style="iconStyle" d="M4.307,0,0,5.743H8.615Z" />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '8',
    },
    width: {
      type: String,
      default: '12',
    },
    color: {
      type: String,
      default: 'icon-color-grey',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
